import { Paper, Typography } from "@mui/material";
import { PushConnectorFormField } from "../../../reducer";
import { PushConnectorFormFields } from "./components";
import { useSelectedPushConnector } from "../../../hook/pushConnector";
import { usePushConnectorForm } from "../../../hook/pushConnector/edit";
import { useEffect } from "react";

export const PushConnectorViewPage: React.FC = () => {
  const [selectedPushConnector, setSelectedPushConnector] =
    useSelectedPushConnector();
  const [pushConnectorForm, setPushConnectorForm] = usePushConnectorForm();
  const selectedPushConnectorId = selectedPushConnector?.id;
  useEffect(() => {
    const pushConnectorForm: PushConnectorFormField[] = Object.entries(
      selectedPushConnector?.data ?? []
    ).map(([label, value]) => ({
      label,
      value,
    }));
    setPushConnectorForm(pushConnectorForm);
  }, [selectedPushConnectorId]);

  return (
    <Paper
      sx={{ padding: 3, gap: 3, display: "flex", flexDirection: "column" }}
    >
      {selectedPushConnector ? (
        <>
          <Typography variant="h3">{selectedPushConnector.name}</Typography>
          <PushConnectorFormFields
            pushConnectorForm={pushConnectorForm}
            disabled={true}
          />
        </>
      ) : (
        <Typography>Loading</Typography>
      )}
    </Paper>
  );
};
