import { useDispatch, useSelector } from "react-redux";
import {
  ActionType,
  PageContentType,
  PushConnectorCreated,
  PushConnectorMap,
  RootState,
} from "../reducer";
import { useAppId, useSelectedPage } from "../hook";
import { getPushConnectors } from "../api";

export const useSelectedPushConnector: () => [
  PushConnectorCreated | undefined,
  (selectedPushConnector: string) => void
] = () => {
  const selectedPushConnector = useSelector((state: RootState) =>
    state.main.selectedPushConnector
      ? state.main.pushConnectors[state.main.selectedPushConnector]
      : undefined
  );
  const dispatch = useDispatch();
  const setSelectedPushConnector = (selectedPushConnector: string) => {
    dispatch({
      type: ActionType.SET_SELECTED_PUSH_CONNECTOR,
      selectedPushConnector,
    });
  };
  return [selectedPushConnector, setSelectedPushConnector];
};

export const usePushConnectors: () => [
  PushConnectorMap,
  (setPushConnectors: PushConnectorMap) => void
] = () => {
  const pushConnectors = useSelector(
    (state: RootState) => state.main.pushConnectors
  );
  const [selectedPushConnector, setSelectedPushConnector] =
    useSelectedPushConnector();
  const [selectedPage, setSelectedPage] = useSelectedPage();
  const dispatch = useDispatch();
  const setPushConnectors = (pushConnectors: PushConnectorMap) => {
    dispatch({
      type: ActionType.SET_PUSH_CONNECTORS,
      pushConnectors,
    });
  };
  return [pushConnectors, setPushConnectors];
};

export const useHandlePushConnectorsResponse: () => (res: any) => void = () => {
  const [pushConnectors, setPushConnectors] = usePushConnectors();
  // const [_, setSelectedPushConnector] = useSelectedPushConnector();

  return (res: any) => {
    const connectorsArray = res?.connectors as PushConnectorCreated[];
    const connectorsMap = Object.fromEntries(
      connectorsArray.map((connector) => [connector.id, connector])
    );
    // setSelectedPushConnector(connectorsArray[0].id);
    setPushConnectors(connectorsMap);
  };
};

export const useFetchPushConnectors: () => () => void = () => {
  const handlePushConnectorsResponse = useHandlePushConnectorsResponse();
  const [appId, setAppId] = useAppId();

  return () => {
    getPushConnectors({ appId: appId! }).then(handlePushConnectorsResponse);
  };
};
