import { ConnectorTypesTable } from "../connectorTypeTable";
import { NoteBlockContainer, NotePageContainer } from "../note";

export const SelectConnectCardPage: React.FC = () => {
  return (
    <NotePageContainer pageName="Add App Connector">
      <NoteBlockContainer name="Applications">
        <ConnectorTypesTable />
      </NoteBlockContainer>
    </NotePageContainer>
  );
};
