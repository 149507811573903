import { useDispatch, useSelector } from "react-redux";
import { useAppId, useSelectedPage } from "../hook";
import {
  ActionType,
  AppDispatch,
  AppUser,
  PageContentType,
  RootState,
} from "../reducer";
import {
  addWorkspaceUser,
  deleteWorkspaceUser,
  getAllUsers,
  getWorkspaceUsers,
  setSelectedApp,
} from "../api";

export const useNavigateSettingsPage: () => () => void = () => {
  const [selectedPage, setSelectedPage] = useSelectedPage();

  return () => {
    setSelectedPage({ contentType: PageContentType.SETTINGS });
  };
};

export const useAllUsers: () => [
  AppUser[],
  (messages: AppUser[]) => void
] = () => {
  const allUsers = useSelector((state: RootState) => state.main.allUsers);
  const dispatch = useDispatch<AppDispatch>();
  const setAllUsers = (allUsers: AppUser[]) =>
    dispatch({ type: ActionType.SET_ALL_USERS, allUsers });
  return [allUsers, setAllUsers];
};

export const useFetchAllUsers: () => () => void = () => {
  const [allUsers, setAllUsers] = useAllUsers();

  return () => {
    getAllUsers().then((response) => {
      setAllUsers(response.users);
    });
  };
};

export const useWorkspaceUsers: () => [
  AppUser[],
  (messages: AppUser[]) => void
] = () => {
  const workspaceUsers = useSelector(
    (state: RootState) => state.main.workspaceUsers
  );
  const dispatch = useDispatch<AppDispatch>();
  const setWorkspaceUsers = (workspaceUsers: AppUser[]) =>
    dispatch({ type: ActionType.SET_WORKSPACE_USERS, workspaceUsers });
  return [workspaceUsers, setWorkspaceUsers];
};

export const useFetchWorkspaceUsers: () => (config: {
  appId: string;
}) => void = () => {
  const [workspaceUsers, setWorkspaceUsers] = useWorkspaceUsers();

  return ({ appId }) => {
    getWorkspaceUsers({ appId }).then((response) => {
      setWorkspaceUsers(response.users);
    });
  };
};

export const useDeleteWorkspaceUser: () => (config: {
  appId: string;
  email: string;
}) => void = () => {
  const fetchWorkspaceUsers = useFetchWorkspaceUsers();
  const fetchAllUsers = useFetchAllUsers();

  return ({ appId, email }) => {
    deleteWorkspaceUser({ appId, email }).then((response) => {
      fetchAllUsers();
      fetchWorkspaceUsers({ appId });
    });
  };
};

export const useAddWorkspaceUser: () => (config: {
  appId: string;
  email: string;
  name: string;
}) => void = () => {
  const fetchWorkspaceUsers = useFetchWorkspaceUsers();
  const fetchAllUsers = useFetchAllUsers();
  const [appId, setAppId] = useAppId();

  return ({ appId: addAppId, email, name }) => {
    addWorkspaceUser({ appId: addAppId, email, name }).then((response) => {
      fetchAllUsers();
      fetchWorkspaceUsers({ appId: appId! });
    });
  };
};

export const useSetSelectedApp: () => (config: {
  appId: string;
  email: string;
}) => void = () => {
  const [appId, setAppId] = useAppId();

  return ({ appId, email }) => {
    setSelectedApp({ appId: appId, email }).then((response) => {
      setAppId(appId);
    });
  };
};
