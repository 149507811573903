import React from "react";
import {
  useDeleteNoteTable,
  useSelectedNote,
  useTable,
  useTableSpec,
} from "../../hook";
import { NoteBlockContainer, NotePageContainer } from "../note";
import { SearchTable, TableType } from "../searchTable";
import { Box } from "@mui/material";

export const NoteBlock: React.FC<{ block: any }> = ({ block }) => {
  const tableId = block?.["table-id"];
  const table = useTable(tableId);
  const tableSpec = useTableSpec(tableId);
  const name = tableSpec?.name ?? table?.name;
  const description = tableSpec?.description;
  const deleteNoteTable = useDeleteNoteTable();
  const tableType = tableSpec?.["execute-error"]
    ? TableType.ERROR_CODE_TABLE
    : TableType.CODE_TABLE;

  return (
    <Box id={table?.["table-id"]}>
      <NoteBlockContainer
        name={name ?? ""}
        description={description}
        onDelete={() => {
          deleteNoteTable({ tableId });
        }}
      >
        <SearchTable
          table={table!}
          tableSpec={tableSpec}
          tableType={tableType}
        />
      </NoteBlockContainer>
    </Box>
  );
};

export const NotePage: React.FC = () => {
  const [selectedNote, setSelectedNote] = useSelectedNote();
  const blocks = selectedNote?.blocks ?? [];
  const pageName = selectedNote?.name;

  return (
    <NotePageContainer pageName={pageName!}>
      {blocks?.map((block: any, index: number) => (
        <NoteBlock block={block} key={index} />
      ))}
    </NotePageContainer>
  );
};
