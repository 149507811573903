import AddIcon from "@mui/icons-material/Add";
import {
  Avatar,
  Box,
  IconButton,
  Table as MuiTable,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useConnectorTypes, useSelectedConnectorType } from "../hook";
import { ConnectorType } from "../reducer";

import * as R from "ramda";

export const ConnectorTypesTable: React.FC<{}> = () => {
  const [connectorTypes, setConnectorTypes] = useConnectorTypes();
  const [selectedConnectorType, setSelectedConnectorType] =
    useSelectedConnectorType();

  const [index, setIndex] = useState(0);
  const header = ["Application"];
  const page = index;
  const setPage = (page: number) => setIndex(page);
  const [filter, setFilter] = useState("");
  const height = "4rem";
  const avatarHeight = "3rem";

  const filterMatches = (connectorType: ConnectorType) =>
    connectorType.name.toLowerCase().includes(filter.toLowerCase());

  const getFilterRows = () => R.filter(filterMatches, connectorTypes);

  const getTableCount = () => getFilterRows().length;

  const [tableCount, setTableCount] = useState(getTableCount());

  const getPageRows = () => {
    const skip = page * 10;
    const filtered = R.filter(filterMatches, connectorTypes);
    const newRows = R.take(10, R.drop(skip, filtered));
    return newRows;
  };
  const [rows, setRows] = useState(getPageRows());

  useEffect(() => {
    setRows(getPageRows());
    setTableCount(getTableCount());
  }, [page, filter]);

  return (
    <>
      <TextField
        label="Search by Name"
        margin="normal"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
      />
      <TableContainer component={Paper}>
        <MuiTable>
          <TableHead>
            <TableRow>
              {header.map((name: any, index: number) => (
                <TableCell sx={{ height }} key={index}>
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((connectorType, rowIndex: number) => {
              const { icon_url, name } = connectorType;
              return (
                <TableRow key={rowIndex}>
                  <TableCell sx={{ height }}>
                    <Box
                      sx={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          setSelectedConnectorType(connectorType);
                        }}
                        color="primary"
                      >
                        <AddIcon />
                      </IconButton>
                      <Typography width={"10rem"}>{name}</Typography>
                      <Avatar
                        alt={name}
                        src={icon_url}
                        style={{
                          width: avatarHeight,
                          height: avatarHeight,
                        }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
      <TablePagination
        component="div"
        onPageChange={(e, value) => setPage(value)}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        page={page}
        count={tableCount}
        showFirstButton={true}
        showLastButton={true}
      />
    </>
  );
};
