import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import React, { ReactNode } from "react";

export const NoteBlockContainer: React.FC<{
  children: ReactNode;
  name: string;
  description?: string;
  onDelete?: () => void;
}> = ({ children, name, onDelete, description }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 3,
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h5">{name}</Typography>
          <Typography variant="subtitle2">{description}</Typography>
        </Box>
        {onDelete && (
          <IconButton color="primary" onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
      {children}
    </Paper>
  );
};

export const NotePageContainer: React.FC<{
  pageName: string;
  children: ReactNode;
}> = ({ pageName, children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <Typography variant="h4">{pageName}</Typography>
      {children}
    </Box>
  );
};
