import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";
import {
  Box,
  Drawer,
  IconButton,
  Link,
  Paper,
  TextField,
  Theme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  useAppId,
  useChatDrawerOpen,
  useChatLoading,
  useMessages,
  useReplyConversation,
  useToggleChatDrawerOpen,
} from "../hook";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Message, MessageRole } from "../reducer";

export const ChatBubble: React.FC<{
  message: Message;
}> = ({ message: { role, content } }) => {
  const isAssistant = role === MessageRole.ASSISTANT;
  const backGroundColor = (theme: Theme) =>
    isAssistant ? theme.palette.grey[900] : theme.palette.grey[800];
  const justifyContent = isAssistant ? "flex-start" : "flex-end";

  return (
    <Box display="flex" justifyContent={justifyContent} p={1}>
      <Paper
        style={{ borderRadius: "16", padding: "10px" }}
        sx={{
          backgroundColor: backGroundColor,
        }}
      >
        <ReactMarkdown
          components={{
            a: ({ node, ...props }) => (
              <Link href={props.href} target={"_blank"} color="primary">
                {props.children}
              </Link>
            ),
          }}
          remarkPlugins={[remarkGfm]}
        >
          {content}
        </ReactMarkdown>
      </Paper>
    </Box>
  );
};

export const ChatInput: React.FC = () => {
  const [chatLoading, setChatLoading] = useChatLoading();
  const [inputValue, setInputValue] = useState(
    // "Can you give me id from google calendar settings."
    ""
  );
  const replyConversation = useReplyConversation();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const sendMessage = () => {
    if (inputValue === "") {
      return;
    }
    replyConversation({ content: inputValue });
    setInputValue("");
  };

  return (
    <Box
      display="flex"
      component="form"
      onSubmit={(event: React.FormEvent) => {
        event.preventDefault();
        if (chatLoading) return;
        sendMessage();
      }}
    >
      <TextField
        onChange={handleInputChange}
        value={inputValue}
        sx={{ display: "flex", flexGrow: 1, paddingRight: 2 }}
        disabled={chatLoading}
      />
      <IconButton
        disabled={chatLoading}
        color="primary"
        onClick={() => {
          sendMessage();
        }}
      >
        <SendIcon />
      </IconButton>
    </Box>
  );
};

export const ChatDrawer: React.FC<{}> = () => {
  const [messages, setMessages] = useMessages();
  const toggleChatDrawerOpen = useToggleChatDrawerOpen();
  const [chatDrawerOpen] = useChatDrawerOpen();

  return (
    <Drawer
      anchor="right"
      open={chatDrawerOpen}
      onClose={toggleChatDrawerOpen}
      PaperProps={{
        style: { width: "80%" },
      }}
    >
      <Box
        sx={{ p: 2, height: "100%" }}
        role="presentation"
        display="flex"
        flexDirection={"column"}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: 2,
          }}
        >
          <IconButton
            color="primary"
            onClick={toggleChatDrawerOpen}
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>
        </Box>
        {messages.map((msg: Message, index: number) => {
          return <ChatBubble key={index} message={msg} />;
        })}
        <Box sx={{ display: "flex", flexGrow: 1 }} />
        <ChatInput />
      </Box>
    </Drawer>
  );
};
