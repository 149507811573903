import { useDispatch, useSelector } from "react-redux";
import {
  ActionType,
  PageContentType,
  PushConnector,
  PushConnectorData,
  PushConnectorFormField,
  RootState,
} from "../../reducer";
import { useAppId, useNotebook, useSelectedPage } from "../../hook";
import {
  createPushConnector,
  deletePushConnector,
  editPushConnector,
} from "../../api";
import {
  useFetchPushConnectors,
  useSelectedPushConnector,
} from "../pushConnector";
import { useSelectMainNote } from "../note";
const R = require("ramda");

export const useGoToEditPushConnectorPage: () => () => void = () => {
  const [selectedPage, setSelectedPage] = useSelectedPage();
  return () => {
    setSelectedPage({
      contentType: PageContentType.EDIT_PUSH_CONNECTOR,
    });
  };
};

export const usePushConnectorForm: () => [
  PushConnectorFormField[],
  (pushConnectorForm: PushConnectorFormField[]) => void
] = () => {
  const pushConnectorForm: PushConnectorFormField[] = useSelector(
    (state: RootState) => state.main.pushConnectorForm
  );
  const dispatch = useDispatch();
  const setPushConnectorForm = (
    pushConnectorForm: PushConnectorFormField[]
  ) => {
    dispatch({
      type: ActionType.SET_PUSH_CONNECTOR_FORM_DATA,
      pushConnectorForm,
    });
  };
  return [pushConnectorForm, setPushConnectorForm];
};

export const useSetPushConnectorFormField: () => (config: {
  index: number;
  field: PushConnectorFormField;
}) => void = () => {
  const [pushConnectorForm, setPushConnectorForm] = usePushConnectorForm();
  return ({ index, field }) => {
    const newPushConnectorForm = R.update(index, field, pushConnectorForm);
    setPushConnectorForm(newPushConnectorForm);
  };
};

export const useAddPushConnectorFormField: () => () => void = () => {
  const [pushConnectorForm, setPushConnectorForm] = usePushConnectorForm();
  return () => {
    setPushConnectorForm([...pushConnectorForm, { label: "", value: "" }]);
  };
};

export const useRemovePushConnectorFormField: () => (
  index: number
) => void = () => {
  const [pushConnectorForm, setPushConnectorForm] = usePushConnectorForm();
  return (index) => {
    const newPushConnectorForm = R.remove(index, 1, pushConnectorForm);
    setPushConnectorForm(newPushConnectorForm);
  };
};

export const useSubmitPushConnectorCreateForm: () => () => void = () => {
  const [appId, setAppId] = useAppId();
  const [pushConnectorForm, setPushConnectorForm] = usePushConnectorForm();
  const fetchPushConnectors = useFetchPushConnectors();
  const selectMainNote = useSelectMainNote();

  return () => {
    const pushConnector = Object.fromEntries(
      pushConnectorForm.map(({ label, value }) => [label, value])
    ) as PushConnectorData;
    selectMainNote();
    createPushConnector({ appId: appId!, pushConnector }).then((res) => {
      fetchPushConnectors();
    });
  };
};

export const useSubmitPushConnectorEditForm: () => () => void = () => {
  const [appId, setAppId] = useAppId();
  const [selectedPushConnector, setSelectedPushConnector] =
    useSelectedPushConnector();
  const [pushConnectorForm] = usePushConnectorForm();
  const fetchPushConnectors = useFetchPushConnectors();
  const selectMainNote = useSelectMainNote();

  return () => {
    const pushConnector = Object.fromEntries(
      pushConnectorForm.map(({ label, value }) => [label, value])
    ) as PushConnectorData;
    selectMainNote();
    editPushConnector({
      appId: appId!,
      pushConnector,
      pushConnectorId: selectedPushConnector?.id!,
    }).then((res) => {
      fetchPushConnectors();
    });
  };
};

export const useDeleteSelectedPushConnector: () => () => void = () => {
  const [appId, setAppId] = useAppId();
  const [selectedPushConnector, setSelectedPushConnector] =
    useSelectedPushConnector();
  const fetchPushConnectors = useFetchPushConnectors();
  const pushConnectorId = selectedPushConnector?.id!;
  const [notebook, setNotebook] = useNotebook();
  const selectMainNote = useSelectMainNote();

  return () => {
    selectMainNote();
    deletePushConnector({ appId: appId!, pushConnectorId }).then((res) => {
      fetchPushConnectors();
    });
  };
};
